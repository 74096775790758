import React from "react";
import {Container, Header, Menu, Rail, Segment} from "semantic-ui-react";
import {Link} from "@reach/router";
import styles from "./foundation.module.css";
import cx from 'classnames';
import DesktopContainer from "../../container/DesktopContainer";


const Principles = () => (
    <Segment className={styles.segment}>
        <DesktopContainer>
            <Rail position='right' className={styles.rail}>
                <Segment raised className={styles.menuSegment}>
                    <Menu secondary vertical className={styles.menu}>
                        <Link to="/stiftung/">
                            <Menu.Item className={styles.menuItem}>Stiftung</Menu.Item>
                        </Link>
                        <Link to="/stiftung/grundsaetze/">
                            <Menu.Item className={styles.menuItem}>Grundsätze</Menu.Item>
                        </Link>
                        <Link to="/stiftung/principles/">
                            <Menu.Item className={cx(styles.menuItem, styles.active)}>Prinzipien</Menu.Item>
                        </Link>
                        <Link to="/stiftung/transparenz/">
                            <Menu.Item className={styles.menuItem}>Transparenz</Menu.Item>
                        </Link>
                        <Link to="/stiftung/anlagerichtlinien/">
                            <Menu.Item className={styles.menuItem}>Anlagerichtlinien</Menu.Item>
                        </Link>
                    </Menu>
                </Segment>
            </Rail>
        </DesktopContainer>
        <Container className={styles.container}>
            <p>Die Deutsche Stiftung Kinderdermatologie folgt den „Principles of Good Practice“ die vom European Foundation Center am 24. Oktober 2006 verabschiedet wurde.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 1: Einhaltung
            </Header>
            <p>Stiftungen entsprechen den Gesetzen des Staates, in dem sie ihren Sitz haben, und handeln in Übereinstimmung mit ihren Statuten. Sie entsprechen den internationalen und europäischen Übereinkommen des Landes, dem sie angehört.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 2: Governance
            </Header>
            <p>Stiftungen haben ein identifizierbares Entscheidungsgremium, dessen Mitglieder und Nachfolger sollten in Übereinstimmung mit den festgelegten Grundsätzen und Verfahren benannt werden, und handeln nach den höchsten ethischen Standards.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 3: Politische Entscheidung, Stiftungsarbeit und Förderprogramm
            </Header>
            <p>Stiftungen definieren klare Richtlinien und Verfahren mit Angabe ihrer Auftrag, Ziele und Programme und überprüfen diese regelmäßig.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 4: Selbstverpflichtung: Verwaltung und Finanzen
            </Header>
            <p>Stiftungen unterstützen die Förderung einer effizienten Organisation und Arbeit, achten auf eine umsichtige und nachhaltige Bewirtschaftung, Anlagestrategie und -verfahren und Nutzung von Ressourcen für das Gemeinwohl. Stiftungen bezeugen eine ordnungsgemäße Buchführung / Buchhaltung nach den geltenden Regeln, in dem sie ihren Sitz haben. Sie können diese Regeln mit zusätzlichen Maßnahmen auf freiwilliger Basis ergänzen.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 5: Angaben und Kommunikation
            </Header>
            <p>Stiftungen agieren in einer transparenten Weise und unter Einhaltung der Vorschriften über Transparenz nach Maßgabe des Landes, in denen sie tätig sind. Sie können diese Regelungen mit zusätzlichen Maßnahmen auf freiwilliger Basis zu ergänzen.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 6: Monitoring und Evaluierung
            </Header>
            <p>Stiftungen organisieren eine geeignete Überwachung und regelmäßige Evaluierung ihrer Maßnahmen und Programme.</p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Prinzip 7: Zusammenarbeit
            </Header>
            <p>Stiftungen teilen ihr Know-how und ihre Erfahrung mit gemeinnützigen Organisationen und anderen relevanten Interessengruppen, um diese in guter Arbeitsweise zu fördern. Stiftungen kooperieren soweit sinnvoll, um ihre Effektivität in ihren jeweiligen Tätigkeitsfeldern.
            </p>
        </Container>
        <Container className={styles.container}>
            <Header as={'h2'}>
                Zu Ihrer weiteren Information
            </Header>
            <p>Das in Brüssel ansässige <a href="https://www.efc.be" className={'primary'}>European Foundation Centre (EFC)</a> fördert und unterstützt die Arbeit von Stiftungen und korporativen Zuwendungsgebern, die in Europa tätig sind. Gegründet 1989 von sieben führenden europäischen Stiftungen zählt das EFC mehr als 160 unabhängige Geldgeber und vernetzt heute mehr als 50.000 Organisationen und Stiftungen in 40 Ländern.</p>
        </Container>
    </Segment>
);

export default Principles;